import { Observable, Subscription, forkJoin, of } from "rxjs";
import { BaseFlowComponent } from "../base-flow-component/base-flow-component";
import { CompleteActivityDismantledSeriesData } from "./complete-activity-dismantled-series.data";
import { Component } from "@angular/core";
import { CompleteActivityDismantledSeriesOrder } from "./complete-activity-dismantled-series-order";
import { RequestUninstallDevicesService } from "../../../../services/request-uninstall-devices.service";
import { NewRequestDevice } from "../../../../models/request/new-request/new-request-device";
import { switchMap, tap } from "rxjs/operators";
import { AttachmentType, DeviceStatus } from "../../../../enums";
import { DeviceTypeEnum } from "../../../../enums/device-type.enum";
import { Attachment, UploadAttachmentsModel, UploadFile } from "../../../../models/attachment";
import { AttachmentsService } from "../../../../services/attachments.service";
import { UploadFileData } from "../../../../models/attachment/upload-file-data";
import { DeviceAttachmentsService } from "../../../../services/device-attachments.service";
import { DismantledSeriesSerialPartNumbersData } from "../dismantled-series-serial-part-numbers/dismantled-series-serial-part-numbers-data";
import { ConfirmAnalyzedSerialImagesDataItem } from "../confirm-analyzed-serial-images/confirm-analyzed-images.data";
import { ConfirmAnalyzedSerialImagesData } from "../confirm-analyzed-serial-images/confirm-analyzed-images.data";
import { ActivitiesService } from "../../../../services/activities.service";

@Component({
    selector: 'complete-activity-dismantled-series',
    templateUrl: './complete-activity-dismantled-series.component.html',
    styleUrls: ['./complete-activity-dismantled-series.component.scss',
        '../../../../../../vendor/libs/ngx-perfect-scrollbar/ngx-perfect-scrollbar.scss',
        '../../../../../../vendor/libs/angular2-ladda/angular2-ladda.scss']
})
export class CompleteActivityDismantledSeriesComponent 
    extends BaseFlowComponent<CompleteActivityDismantledSeriesData>
{
    uploadedAttachmentIds: number[] = [];
    StepOrder = CompleteActivityDismantledSeriesOrder;

    stepOrdering = [
        CompleteActivityDismantledSeriesOrder.dismantledImages,
        CompleteActivityDismantledSeriesOrder.confirmAnalyzedData,
        CompleteActivityDismantledSeriesOrder.serialAndPartNumbers,
        CompleteActivityDismantledSeriesOrder.generalDismantledProperties
    ];

    currentStepIndex: number = 0;

    constructor(
        public requestUninstallDevicesService: RequestUninstallDevicesService,
        public attachmentsService: AttachmentsService,
        public deviceAttachmentsService: DeviceAttachmentsService,
        public activitiesService: ActivitiesService) 
    {
        super();
    }

    initData() {
        this.reloadAllAttachments();
        this.currentStepIndex = this.isForwardMovement ? 0 : this.stepOrdering.length - 1;
    }

    saveChanges(): Observable<any> {
        let requestDevice = new NewRequestDevice;
        requestDevice.requestId = this.data.requestId;
        requestDevice.requestDeviceId = this.data.requestUninstallDeviceId;
        requestDevice.serialNumber = this.data.serialAndPartNumbersData.serialNumber;
        requestDevice.partNumber = this.data.serialAndPartNumbersData.partNumber;
        requestDevice.nomenclature = this.data.serialAndPartNumbersData.nomenclature;
        requestDevice.deviceStatusId = DeviceStatus.toUninstall;
        requestDevice.deviceTypeId = DeviceTypeEnum.terminal;

        requestDevice.deviceConditionId = this.data.generalPropertiesData.deviceConditionId;
        requestDevice.isDeviceBroken = this.data.generalPropertiesData.isDeviceBroken;
        requestDevice.brokenDescription = this.data.generalPropertiesData.brokenDescription;
        requestDevice.completeKit = this.data.generalPropertiesData.completeKit;
        requestDevice.hasCryptoKey = this.data.generalPropertiesData.hasCryptoKey;
        requestDevice.cryptoKey = this.data.generalPropertiesData.cryptoKey;

        return this.data.requestUninstallDeviceId == null
            ? this.requestUninstallDevicesService.create(requestDevice, null, true, true)
                .pipe(
                    tap(resp => { 
                        this.data.requestUninstallDeviceId = resp.data; 
                    }),
                    switchMap(() => {                            
                        return this.uploadAttachmentsRequest();
                    }))
            : this.requestUninstallDevicesService.update(requestDevice, null, true, true)
                .pipe(
                    switchMap(() => {    
                        return this.uploadAttachmentsRequest();
                    })
                );
    }

    goToNextStep(): void {  
        if (this.needAnalyze) {
            this.analyzeSerialAttachments();
        }
        else if (this.currentStepIndex == this.stepOrdering.length - 1) {
            this.goToNext();
        }
        else {
            this.currentStepIndex++;
        }
    }

    goToPreviousStep(): void {
        this.currentStepIndex--;

        if (this.currentStepNumber == this.StepOrder.serialAndPartNumbers &&
            this.data.serialAndPartNumbersData.analyzeSuccessful) {
            this.currentStepIndex--;
        }

        if (this.currentStepIndex < 0) {
            this.goToPrevious();
        }
    }

    manualCorrect() {
        this.currentStepIndex++;
    }

    removeFile(file: UploadFileData){
        if (file && file.isUploadedOnServer) {
            this.attachmentsService.remove(file.uid, null, null, true).subscribe(() => {
                this.reloadAllAttachments();
            });
        }
    }

    cancelAnalyzing() {
        this.data.imagesData.requestDeviceImages.forEach(x => this.removeFile(x));
        this.data.imagesData.requestDeviceSerialNumberImages.forEach(x => this.removeFile(x));
        this.data.serialAndPartNumbersData = new DismantledSeriesSerialPartNumbersData;
        this.goToPreviousStep();
    }

    goToNextAfterConfirmation() {
        if (this.data.serialAndPartNumbersData.analyzeSuccessful) {
            this.currentStepIndex += 2;
        }
        else {
            this.goToNextStep();
        }
    }

    get currentStepNumber(): number {
        return this.stepOrdering[this.currentStepIndex];
    }

    get needAnalyze(): boolean {
       return this.useImageAnalyze &&
              this.isForwardMovement && 
              this.currentStepNumber == this.StepOrder.dismantledImages &&
              this.data.imagesData.requestDeviceSerialNumberImages.length > 0;
    }

    private analyzeSerialAttachments() {
        this.dataSaving = true;

        this.activitiesService.getById(this.data.activityId).subscribe(activity => {
            let $loadRequestDeviceImage = this.loadAttachmentsForType(AttachmentType.requestDeviceImage, activity.entity.attachments);
            let $loadUninstalledDevicesFotos = this.loadAttachmentsForType(AttachmentType.uninstalledDevicesFotos, activity.entity.attachments);

            forkJoin([$loadRequestDeviceImage, $loadUninstalledDevicesFotos]).subscribe((result) => {
                this.data.imagesData.requestDeviceImages = result[0];
                this.data.imagesData.requestDeviceSerialNumberImages = result[1];

                var attachmentIds = this.data.imagesData.requestDeviceImages.map(x => +x.uid);
                this.data.imagesData.requestDeviceSerialNumberImages.forEach(x => attachmentIds.push(+x.uid))
                this.deviceAttachmentsService.analyzeUninstallAttachments(attachmentIds)
                    .subscribe((resp) => {
                        this.data.serialAndPartNumbersData.analyzeSuccessful = resp.data.isSuccessful;
                        this.data.serialAndPartNumbersData.serialNumber = resp.data.serialNumber;
                        this.data.serialAndPartNumbersData.partNumber = resp.data.partNumber;
                        this.data.serialAndPartNumbersData.nomenclature = resp.data.nomenclature;
                        this.data.serialAndPartNumbersData.serialNumberAvailable = resp.data.serialNumberAvailable;
                        this.data.serialAndPartNumbersData.serialNumberRecognized = resp.data.serialNumberRecognized;
                        this.data.serialAndPartNumbersData.partNumberAvailable = resp.data.partNumberAvailable;
                        this.data.serialAndPartNumbersData.partNumberRecognized = resp.data.partNumberRecognized;
                        this.data.serialAndPartNumbersData.requestDeviceAttachmentCorrect = resp.data.requestDeviceAttachmentCorrect;
        
                        if (resp.data.serialNumberRecognized && !resp.data.serialNumberAvailable) {
                            resp.data.serialNumber = '';
                        }
                        if (resp.data.partNumberRecognized && !resp.data.partNumberRecognized) {
                            resp.data.partNumber = '';
                        }

                        this.currentStepIndex++;
                        this.dataSaving = false;
                    });
            });
        });
    }

    uploadAndReload() {
        this.uploadAttachmentsRequest().subscribe(() => this.reloadAllAttachments());
    }

    reloadAllAttachments(){
        this.activitiesService.getById(this.data.activityId).subscribe(activity => {
            this.loadAttachmentsForType(AttachmentType.requestDeviceImage, activity.entity.attachments).subscribe(x => {
                this.data.imagesData.requestDeviceImages = x;
                this.data.imagesData.requestDeviceImages.forEach(x => x.isUploadedOnServer = true);
            });
            this.loadAttachmentsForType(AttachmentType.uninstalledDevicesFotos, activity.entity.attachments).subscribe(x => {
                this.data.imagesData.requestDeviceSerialNumberImages = x;
                this.data.imagesData.requestDeviceSerialNumberImages.forEach(x => x.isUploadedOnServer = true);
            });
        });
    }

    loadAttachmentsForType(type: AttachmentType, attachments: Attachment[]): Observable<UploadFileData[]>{
        return new Observable((observer) => {
            var files = attachments
            .filter(x => x.attachmentTypeIds.includes(type))
            .map(attachment => {
                return this.loadFileData(attachment);
            });
            Promise.all(files)
                .then((results) => {
                    observer.next(results);
                    observer.complete();
                });
          });
    }

    async loadFileData(attachment: Attachment) { 
        let file = new UploadFileData;
        file.uid = attachment.attachmentId.toString();
        file.fileName = attachment.attachmentName;
        file.fileSize = attachment.attachmentSize;
        file.fileExtension = attachment.attachmentExtension;
        file.isUploadedOnServer = true;

		let blob = await this.attachmentsService.getAttachmentById(attachment.attachmentId).toPromise();
        var reader = new FileReader();
		reader.readAsDataURL(blob); 
		reader.onloadend = function() {
			file.fileData = (reader.result as string).split(',')[1];
		}

        return file;
    }

    private uploadAttachmentsRequest(): Observable<any> {
        this.data.imagesData.requestDeviceImages.forEach(x => {
            x.attachmentTypeId = AttachmentType.requestDeviceImage;
            x.isTemporary = true;
        });
        this.data.imagesData.requestDeviceSerialNumberImages.forEach(x => {
            x.attachmentTypeId = AttachmentType.uninstalledDevicesFotos;
            x.isTemporary = true;
        });

        let attachments = this.data.imagesData.requestDeviceImages.concat(this.data.imagesData.requestDeviceSerialNumberImages);
        let attachmentsToUpload = attachments.filter(x => !x.isUploadedOnServer && !x.isRemoved);
        let attachmentsToRemove = attachments.filter(x => x.isUploadedOnServer && x.isRemoved);

        const uploadModel = new UploadAttachmentsModel({ 
            requestId: this.data.requestId, 
            activityId: this.data.activityId,
            requestUninstallDeviceId: this.data.requestUninstallDeviceId,
            attachments: attachmentsToUpload
        });

        let attachmentObservables$: Observable<any>[] = [];

        if (attachmentsToRemove.length > 0) {
            attachmentObservables$ = attachmentsToRemove.map((x) => this.attachmentsService.remove(x.uid, null, null, true));
        }

        if (attachmentsToUpload.length > 0) {
            attachmentObservables$.push(
                this.attachmentsService.uploadWithResumableIfMobile(uploadModel)
                .pipe(tap((resp) => {
                    if (resp && resp.body && resp.body.data) {
                        this.uploadedAttachmentIds = resp.body.data;
                    }
                    attachmentsToUpload.forEach(x => x.isUploadedOnServer = true);
                })));
        }

        return attachmentObservables$.length > 0 
            ? forkJoin(attachmentObservables$) 
            : of({}); 
    }

    get confirmationAnalyzedData(): ConfirmAnalyzedSerialImagesData {
        return {
            withError: !this.data.serialAndPartNumbersData.analyzeSuccessful,
            requestDeviceAttachmentCorrect: this.data.serialAndPartNumbersData.requestDeviceAttachmentCorrect, 
            items: [{
                serialNumber: this.data.serialAndPartNumbersData.serialNumber,
                partNumber: this.data.serialAndPartNumbersData.partNumber,
                nomenclature: this.data.serialAndPartNumbersData.nomenclature
            } as ConfirmAnalyzedSerialImagesDataItem]
        } as ConfirmAnalyzedSerialImagesData;
    }
}